import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import "typeface-lato"

import Header from "./header"

const Layout = ({ children }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
      facebook: file(relativePath: { eq: "facebook.png" }) {
        childImageSharp {
          fixed(height: 32) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <>
      <Header siteTitle={data.site.siteMetadata.title} />
      <main>{children}</main>
      <footer className="footer is-flex-desktop justify-space-between-desktop">
        <div>© {new Date().getFullYear()} Cavalry Basketball</div>
        <div className="has-margin-top-15-touch">
          <a
            href="https://www.facebook.com/groups/142009081306340/"
            target="_blank"
            rel="noopener noreferrer"
            title="Cavalry Basketball Facebook"
          >
            <Img
              fixed={data.facebook.childImageSharp.fixed}
              alt="Cavalry Basketball Facebook"
            />
          </a>
        </div>
        <div className="second has-margin-top-15-touch">
          Icons by{" "}
          <a href="https://www.flaticon.com/authors/freepik" title="Freepik">
            Freepik
          </a>{" "}
          from{" "}
          <a href="https://www.flaticon.com/" title="Flaticon">
            flaticon.com
          </a>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
