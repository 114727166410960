import React, { useState } from "react"
import { Link, graphql, useStaticQuery, navigate } from "gatsby"
import { Location } from "@reach/router"
import Img from "gatsby-image"

const Header = () => {
  const [burgerActive, setBurgerActive] = useState(false)
  const data = useStaticQuery(graphql`
    query HeaderQuery {
      logo: file(relativePath: { eq: "cavalry-logo.png" }) {
        childImageSharp {
          fixed(height: 80) {
            ...GatsbyImageSharpFixed_tracedSVG
          }
        }
      }
    }
  `)

  return (
    <Location>
      {({ location }) => (
        <nav className="navbar" role="navigation" aria-label="main navigation">
          <div className="container">
            <div className="navbar-brand">
              {location.pathname === "/" && <div style={{ height: "96px" }} />}
              {location.pathname !== "/" && (
                <div className="navbar-item" onClick={() => navigate("/")}>
                  <Img
                    fixed={data.logo.childImageSharp.fixed}
                    alt="Cavalry Basketball"
                  />
                </div>
              )}
              <a
                role="button"
                className={`navbar-burger burger ${
                  burgerActive ? "is-active" : ""
                }`}
                aria-label="menu"
                aria-expanded="false"
                data-target="cav-navbar-menu"
                onClick={() => setBurgerActive(!burgerActive)}
              >
                <span aria-hidden="true" />
                <span aria-hidden="true" />
                <span aria-hidden="true" />
              </a>
            </div>
            <div
              id="cav-navbar-menu"
              className={`navbar-menu ${burgerActive ? "is-active" : ""}`}
            >
              <div className="navbar-end has-text-centered is-size-4-tablet">

                <Link className="navbar-item is-size-4-touch" to="/calendar">
                  Calendar
                </Link>

                <Link className="navbar-item is-size-4-touch" to="/coaches">
                  Coaches
                </Link>

                <Link className="navbar-item is-size-4-touch" to="/parents">
                  Parents
                </Link>

                <Link className="navbar-item is-size-4-touch" to="/players">
                  Players
                </Link>

                <Link className="navbar-item is-size-4-touch" to="/teams">
                  Teams
                </Link>

                {/*<div className="navbar-item has-dropdown is-hoverable">*/}
                {/*  <Link className="navbar-link" to="/">*/}
                {/*    More*/}
                {/*  </Link>*/}

                {/*  <div className="navbar-dropdown">*/}
                {/*    <Link className="navbar-item" to="/">*/}
                {/*      About*/}
                {/*    </Link>*/}
                {/*    <Link className="navbar-item" to="/">*/}
                {/*      Jobs*/}
                {/*    </Link>*/}
                {/*    <Link className="navbar-item" to="/">*/}
                {/*      Contact*/}
                {/*    </Link>*/}
                {/*    <hr className="navbar-divider" />*/}
                {/*    <Link className="navbar-item" to="/">*/}
                {/*      Report an issue*/}
                {/*    </Link>*/}
                {/*  </div>*/}
                {/*</div>*/}
              </div>
            </div>
          </div>
        </nav>
      )}
    </Location>
  )
}

export default Header
