import React from "react"
import "../styles/all.scss"

const HeroBar = ({ subtitle = "" }) => {
  return (
    <div className="hero is-primary">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title">Cavalry Basketball</h1>
          <h2 className="subtitle has-text-warning">{subtitle}</h2>
        </div>
      </div>
    </div>
  )
}

export default HeroBar
